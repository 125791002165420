<template>
	<div class="my_inquiry">
		<el-tabs class="tabs" v-model="inquiry_type" @tab-click="handleClick">
			<el-tab-pane label="全部" name="all"></el-tab-pane>
			<el-tab-pane label="待报价" name="0"></el-tab-pane>
			<el-tab-pane label="待审核" name="1"></el-tab-pane>
			<el-tab-pane label="待下单" name="2"></el-tab-pane>
			<el-tab-pane label="已成交" name="3"></el-tab-pane>
		</el-tabs>
		<div class="search_area">
			<div>
				询价编号：
				<input type="text" placeholder="请输入询价编号" v-model="inquiry_no">
				商品名称：
				<input type="text" placeholder="请输入商品名称" v-model="sku_name">
			</div>
			<div>
				询价时间：
				<el-date-picker @change="changeTimeLimit" v-model="date_limit" type="daterange" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
				</el-date-picker>
				<div class="btn" @click="refresh">搜索</div>
			</div>
			
		</div>
		<div class="table_tits">
			<div>询价编号</div>
			<div>商品信息</div>
			<div>询价人</div>
			<div>询价数量</div>
			<div>询价状态</div>
			<div>成交单价</div>
			<div>询价时间</div>
			<div>操作</div>
		</div>
		<div v-loading="loading">
			<div class="inquiry_list">
				<div class="item" v-for="(item,index) in inquiry_list" :key="index">
					<div class="number">{{item.inquiry_no}}</div>
					<div class="name">{{item.sku_name}}</div>
					<div class="name">{{item.inquiry_nickname}}</div>
					<div class="number">{{item.num}}</div>
					<div class="price">{{item.status_name}}</div>
					<div class="price">{{item.inquiry_price}}</div>
					<div class="time">{{$util.timeStampTurnTime(item.create_time)}}</div>
					<div class="btns">
						<div class="btn" @click="lookInquiryDetail(item)">询价详情</div>
						<div class="btn" v-if="item.status == 3" @click="toOrderDetail(item.order_id)">订单详情</div>
						<div class="btn" @click="createInquiryOrder(item)" v-if="item.status == 2">去下单</div>
					</div>
				</div>
			</div>
		</div>
		<div class="pager">
			<el-pagination background :pager-count="5" :total="total" prev-text="上一页" next-text="下一页"
				:current-page.sync="currentPage" :page-size.sync="pageSize" @size-change="handlePageSizeChange"
				@current-change="handleCurrentPageChange" hide-on-single-page></el-pagination>
		</div>
		<el-dialog :visible.sync="inquiry_detail" class="inquiry_detail" :show-close="false">
			<div class="detai_content">
				<div class="title">询价详情
					<div class="el-icon-close" @click="inquiry_detail = false"></div>
				</div>
				<!-- <div class="order_info">
					<div class="tit">订单信息</div>
					<div class="info">
						<div class="item"><div class="left">询价编号：</div>{{show_inquiry_info.inquiry_no}}<div class="el-icon-document-copy" @click="$util.copy(show_inquiry_info.inquiry_no)"></div></div>
						<div class="item"><div class="left">商品名称：</div>{{show_inquiry_info.sku_name}}</div>
						<div class="item"><div class="left">咨询数量：</div>{{show_inquiry_info.num}}</div>
					</div>
				</div> -->
				<div class="form_info">
					<div class="tit">询价信息</div>
					<div class="condition">
						<div class="item"><div class="left">询价编号：</div>{{show_inquiry_info.inquiry_no}}<div class="el-icon-document-copy" @click="$util.copy(show_inquiry_info.inquiry_no)"></div></div>
						<div class="item"><div class="left">商品名称：</div>{{show_inquiry_info.sku_name}}</div>
					</div>
					<div class="condition">
						<div v-if="item.controller != 'Img'" v-for="(item,index) in show_form_info" :key="item.id" class="item"><div class="left">{{item.value.title}}：</div>{{item.val}}</div>
					</div>
					<div v-if="item.controller == 'Img'" v-for="(item,index) in show_form_info" :key="item.id" class="img_item">
						{{item.value.title}}：<img v-for="i in item.val.split(',')" :src="$img(i)" />
					</div>
				</div>
				<div class="order_info"  v-if="show_inquiry_info.quotation_name">
					<div class="tit">报价信息</div>
					<div class="info">
						<div class="item"><div class="left">报价人：</div>{{show_inquiry_info.quotation_name}}</div>
						<div class="item"><div class="left">报价时间：</div>{{$util.timeStampTurnTime(show_inquiry_info.quotation_time)}}</div>
						<div class="item"><div class="left">报价金额：</div>{{show_inquiry_info.inquiry_price}}</div>
					</div>
				</div>
				<div class="order_info order_info2" v-if="show_inquiry_info.quotation_name && show_inquiry_info.examine_name">
					<div class="tit">审核</div>
					<div class="info">
						<div class="item"><div class="left">审核人：</div>{{show_inquiry_info.examine_name}}</div>
						<div class="item"><div class="left">审核时间：</div>{{$util.timeStampTurnTime(show_inquiry_info.examine_time)}}</div>
						<div class="item"><div class="left">审核状态：</div>{{show_inquiry_info.status_name}}</div>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import { inquiryList, inquiryDetail } from '@/api/organization'
	export default {
		data() {
			return {
				inquiry_type: 'all',
				loading: true,
				inquiry_no:'',
				sku_name:'',
				start_time:'',
				end_time:'',
				total: 0,
				currentPage: 1,
				pageSize: 10,
				inquiry_list:[],
				date_limit:'',
				inquiry_detail:false,
				show_form_info:{},
				show_inquiry_info:{},
			}
		},
		created() {
			this.getList();
		},
		methods: {
			toOrderDetail(order_id){
				this.$router.push('/member/bulk_detail?order_id='+order_id)
			},
			lookInquiryDetail(item){
				inquiryDetail({
					inquiry_id:item.inquiry_id
				})
				.then(res=>{
					if(res.code>=0){
						this.inquiry_detail = true;
						this.show_inquiry_info = res.data;
						this.show_form_info = JSON.parse(res.data.form_data);
						console.log(this.show_form_info,48)
					}else{
						this.$message({message:res.message})
					}
				})
			},
			createInquiryOrder(item){
				var data = {
					sku_id: item.sku_id,
					num: item.num,
					inquiry_id:item.inquiry_id
				}
				this.$store.dispatch("order/setOrderCreateData", data)
				this.$router.push('/payment');
			},
			changeTimeLimit(e){
				if(e){
					this.start_time = e[0];
					this.end_time = e[1];
				}
			},
			handleCurrentPageChange(page) {
				this.currentPage = page
				this.getList();
			},
			handlePageSizeChange(size) {
				this.pageSize = size
				this.getList();
			},
			handleClick(tab, event) {
				this.inquiry_type = tab.name;
				this.refresh();
			},
			refresh() {
				this.loading = true;
				this.getList();
			},
			getList(){
				inquiryList({
					page:this.currentPage,
					page_size:this.pageSize,
					status:this.inquiry_type == 'all' ? '' : this.inquiry_type,
					start_time:this.start_time,
					end_time:this.end_time,
					sku_name:this.sku_name,
					inquiry_no:this.inquiry_no,
				})
				.then(res=>{
					if(res.code>=0){
						this.inquiry_list = res.data.list;
						this.total = res.data.count;
						this.loading = false;
					}
				})
			},
		},
	}
</script>

<style lang="scss">
	.inquiry_detail {
		.el-dialog{
			width: 47%;
		}
		.el-dialog__header{
			padding: 0;
		}
		.el-dialog__body{
			padding: 0;
			max-height: 600px;
			overflow-y: auto;
			.detai_content{
				.title{
					padding: 22px 30px 16px 28px;
					color: #111;
					font-size: 18px;
					line-height: 22px;
					font-weight: 600;
					border-bottom: 1px solid #D8D8D8;
					margin-bottom: 28px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					.el-icon-close{
						font-size: 18px;
						cursor: pointer;
					}
				}
				.order_info{
					padding-left: 40px;
					padding-right: 20px;
					margin-bottom: 20px;
					.tit{
						font-size: 16px;
						line-height: 20px;
						font-weight: 600;
						margin-bottom: 25px;
						color: #333;
					}
					.info{
						display: flex;
						align-items: center;
						flex-wrap: wrap;
						padding: 0 10px;
						.item{
							color: #666;
							font-size: 14px;
							line-height: 20px;
							min-width: 50%;
							box-sizing: border-box;
							padding-right: 10px;
							margin-bottom: 18px;
							display: flex;
							align-items: center;
							.left{
								color: #222;
								font-size: 14px;
								line-height: 20px;
								margin-right: 18px;
								min-width: 100px;
								text-align: left;
							}
							.el-icon-document-copy{
								color: #6D7278;
								font-size: 18px;
								margin-left: 12px;
								cursor: pointer;
							}
						}
					}
				}
				.form_info{
					margin-top: 30px;
					padding-left: 38px;
					padding-right: 20px;
					padding-bottom: 26px;
					.tit{
						font-size: 16px;
						line-height: 20px;
						margin-bottom: 25px;
						color: #333;
						font-weight: 600;
					}
					.condition{
						padding: 0 10px;
						display: flex;
						align-items: center;
						flex-wrap: wrap;
						.item{
							color: #666;
							font-size: 15px;
							line-height: 20px;
							min-width: 50%;
							box-sizing: border-box;
							padding-right: 10px;
							margin-bottom: 18px;
							display: flex;
							align-items: center;
							.left{
								color: #222;
								font-size: 14px;
								line-height: 20px;
								margin-right: 18px;
								min-width: 100px;
								text-align: left;
							}
						}
					}
					.img_item{
						padding-left: 30px;
						display: flex;
						align-items: flex-start;
						img{
							width: 60px;
							height: 60px;
							margin-right: 10px;
							display: block;
							border-radius: 5px;
						}
					}
				}
				
			}
		}
	}
	.my_inquiry{
		min-height: 730px;
		box-sizing: border-box;
		background-color: #fff;
		padding: 18px 25px 18px 18px;
		.tabs{
			margin-bottom: 16px;
			.el-tabs__header{
				margin-bottom: 0;
			}
			.el-tabs__nav-wrap::after{
				height: 1px;
				background-color: #E0E0E0;
			}
			.el-tabs__active-bar{
				display: none;
				width: 126px !important;
				top: 0 !important;
				left: -34px !important;
			}
			.el-tabs__item{
				box-sizing: border-box;
				padding: 0 !important;
				height: auto;
				color: #333;
				font-size: 14px;
				line-height: 20px;
				padding: 8px 34px !important;
				background-color: #F7F8FA !important;
				border: 1px solid #E0E0E0;
				border-bottom: none !important;
				border-right: none;
				&.is-top:last-child{
					border-right: 1px solid #E0E0E0;
				}
				&.is-active{
					border-top: 2px solid #00A98F;
					line-height: 21px !important;
					color: #00A98F;
					background-color: #fff !important;
				}
			}
		}
		.search_area{
			display: flex;
			align-items: flex-start;
			flex-direction: column;
			>div{
				display: flex;
				align-items: center;
				margin-bottom: 12px;
			}
			.el-date-editor{
				border: 1px solid #E0E0E0;
				border-radius: 0;
				height: auto;
				padding: 8px 10px;
				.el-range-separator{
					line-height: 20px;
				}
				.el-input__icon{
					line-height: 1;
				}
				.el-range-input{
					border: none;
					color: #333;
					font-size: 14px;
					line-height: 20px;
					padding: 0;
				}
			}
			input{
				border: 1px solid #E0E0E0;
				outline: none;
				color: #333;
				font-size: 14px;
				line-height: 20px;
				padding: 8px 12px;
				margin-right: 12px;
			}
			input::placeholder{
				color: #888;
				font-size: 14px;
				line-height: 20px;
			}
			.btn{
				color: #FFF;
				font-size: 12px;
				line-height: 16px;
				padding: 6px 18px;
				border-radius: 2px;
				background-color: #00A98F;
				margin-left: 10px;
				cursor: pointer;
			}
		}
		.table_tits{
			border: 1px solid #E0E0E0;
			background-color: #F7F8FA;
			padding: 12px 0 12px 24px;
			display: flex;
			align-items: center;
			div{
				color: #333;
				font-size: 14px;
				line-height: 20px;
				&:first-child{
					width: 17%;
				}
				&:nth-child(2){
					width: 21%;
				}
				&:nth-child(3){
					width: 9%;
				}
				&:nth-child(4){
					width: 8%;
				}
				&:nth-child(5){
					width: 9%;
				}
				&:nth-child(6){
					flex: 1;
				}
				&:nth-child(7){
					width: 18%;
				}
				&:nth-child(8){
					width: 9%;
				}
			}
		}
		.inquiry_list{
			border: 1px solid #E0E0E0;
			border-top: none;
			.item{
				display: flex;
				align-items: center;
				padding: 21px 0 15px 24px;
				border-bottom: 1px solid #E0E0E0;
				&:last-child{
					border-bottom: none;
				}
				>div{
					&:first-child{
						width: 17%;
					}
					&:nth-child(2){
						width: 21%;
					}
					&:nth-child(3){
						width: 9%;
					}
					&:nth-child(4){
						width: 8%;
					}
					&:nth-child(5){
						width: 9%;
					}
					&:nth-child(6){
						flex: 1;
					}
					&:nth-child(7){
						width: 18%;
					}
					&:nth-child(8){
						width: 9%;
					}
				}
				.name,.number,.contact,.phone,.time{
					color: #333333;
					font-size: 14px;
					line-height: 20px;
					font-family: auto;
				}
				.name{
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
					padding-right: 10px;
					box-sizing: border-box;
				}
				.btns{
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					.btn{
						color: #105CFB;
						font-size: 14px;
						line-height: 20px;
						margin-bottom: 12px;
						cursor: pointer;
						&:last-child{
							margin-bottom: 0;
						}
					}
				}
			}
		}
		.pager{
			margin-top: 20px;
		}
	}
	.order_info2{
		padding-bottom: 30px;
	}
</style>